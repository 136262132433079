exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landingPage.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-category-summaries-page-js": () => import("./../../../src/templates/categorySummariesPage.js" /* webpackChunkName: "component---src-templates-category-summaries-page-js" */),
  "component---src-templates-summaries-browser-js": () => import("./../../../src/templates/summariesBrowser.js" /* webpackChunkName: "component---src-templates-summaries-browser-js" */),
  "component---src-templates-summary-page-js": () => import("./../../../src/templates/summaryPage.js" /* webpackChunkName: "component---src-templates-summary-page-js" */)
}

